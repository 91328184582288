import React, { Component } from "react";

class MainUSP extends Component {
  render() {
    return (
      <section className="main-usp">
        <div className="container">
          <div className="row negative-margin" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="600">
            <div className="col-12 text-center">
              <h2 className="section-heading text-gray">WHAT IS MEKA?</h2>
              <p className="text-white">The three tech pillars that represent Meka Games.</p>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="usp-wrapper text-center text-lg-left">
                <span className="usp-icon"><i className="fa-solid fa-gamepad-modern"></i></span>
                <h3 className="usp-heading light-blue">ONLINE GAMING</h3>
                <p className="text-white">
                  Experience premium, seamless gaming across a diverse range of titles on our secure, user-friendly platform.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="usp-wrapper text-center text-lg-left">
                <span className="usp-icon"><i className="fa-solid fa-money-bill-transfer"></i></span>
                <h3 className="usp-heading light-blue">PAYMENT SOLUTIONS</h3>
                <p className="text-white">
                  Embrace secure, transparent financial transactions with our low-fee, blockchain-based payment system.
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="usp-wrapper text-center text-lg-left">
                <span className="usp-icon"><i className="fa-solid fa-message-bot"></i></span>
                <h3 className="usp-heading light-blue">BOT AUTOMATION</h3>
                <p className="text-white">
                  Boost efficiency and cut costs with our high-precision bots automating routine tasks and customer service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
  
export default MainUSP;