import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="container-fluid footer">
        <div className="row">
          <div className="col-12 text-center">
            <p className="copyright-text my-2">&copy; {(new Date().getFullYear())} All Rights Reserved, Meka Games</p>
          </div>
        </div>
      </div>
    );
  }
}
  
export default Footer;