import React, { Component} from "react";

import GameLoadingCover from "../assets/img/game-loading.jpg";

class Portfolio extends Component {
  render() {
    return (
      <section className="portfolio-section">
        <div className="container-fluid fluid-padding">
          <div className="row px-2 py-5">
            <div className="col-12 text-center" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="200">
              <h2 className="section-heading dark-blue">EXPLORE MEKA</h2>
            </div>
            <div className="col-sm-6 col-md-4 px-2 my-3">
              <div className="portfolio-container" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="300">
                <img className="portfolio-cover" src={GameLoadingCover} alt="explore-cover"></img>
                <div className="portfolio-details text-center py-3">
                  <h3 className="text-white mb-0">GENESIS MEKA</h3>
                  <p className="text-white mb-0"><strong>NFT Collection</strong></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 px-2 my-3">
              <div className="portfolio-container" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="400">
                <img className="portfolio-cover" src={GameLoadingCover} alt="explore-cover"></img>
                <div className="portfolio-details text-center py-3">
                  <h3 className="text-white mb-0">$MEKA Coin</h3>
                  <p className="text-white mb-0"><strong>Crypto Token</strong></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 px-2 my-3">
              <div className="portfolio-container" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="500">
                <img className="portfolio-cover" src={GameLoadingCover} alt="explore-cover"></img>
                <div className="portfolio-details text-center py-3">
                  <h3 className="text-white mb-0">CDAO X MEKA</h3>
                  <p className="text-white mb-0"><strong>Collaboration</strong></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 px-2 my-3">
              <div className="portfolio-container" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="300">
                <img className="portfolio-cover" src={GameLoadingCover} alt="explore-cover"></img>
                <div className="portfolio-details text-center py-3">
                  <h3 className="text-white mb-0">MEKAPAY</h3>
                  <p className="text-white mb-0"><strong>Web3 Payment Solutions</strong></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 px-2 my-3">
              <div className="portfolio-container" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="400">
                <img className="portfolio-cover" src={GameLoadingCover} alt="explore-cover"></img>
                <div className="portfolio-details text-center py-3">
                  <h3 className="text-white mb-0">MEKA MERCH</h3>
                  <p className="text-white mb-0"><strong>Coming Soon!</strong></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 px-2 my-3">
              <div className="portfolio-container" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="500">
                <img className="portfolio-cover" src={GameLoadingCover} alt="explore-cover"></img>
                <div className="portfolio-details text-center py-3">
                  <h3 className="text-white mb-0">GENESIS MEKA</h3>
                  <p className="text-white mb-0"><strong>NFT Collection</strong></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
  
export default Portfolio;