// Dependencies
import React from "react";
import { BrowserRouter as Router,Route,Routes,useParams} from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Components
// import BGM from "./components/background-music";
import Header from "./components/header";
import Footer from "./components/footer";
import HeroBanner from "./components/hero-banner";
import MainUSP from "./components/main-usp";
import BrandLogos from "./components/brand-logo";
import FeaturedSlider from "./components/featured-slider";
import Portfolio from "./components/portfolio";
import MekaData from "./components/meka-data";
import BuildWithUs from "./components/build-with-us";
import JoinCommunity from "./components/join-community";

function App() {
  AOS.init();
  return (
    <Router>
      <Header/>
      <div className="main-content">
        <HeroBanner/>
        <MainUSP/>
        <BrandLogos/>
        <MekaData/>
        <FeaturedSlider/>
        <Portfolio/>
        <JoinCommunity/>
        <BuildWithUs/>
      </div>
      <Footer/>
    </Router>
  );
}

export default App;