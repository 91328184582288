import React, { Component} from "react";

class BuildWithUs extends Component {
  render() {
    return (        
      <section className="build-with-us">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h3 className="section-heading pastel-purple">BUILT FOR VICTORY</h3>
            </div>
            <div className="col-md-6 col-lg-4 px-2 mt-3">
              <div className="build-wrapper">
                <h3 className="build-heading pastel-purple">OPENSOURCE API</h3>
                <p className="text-white">
                  Experience premium, seamless gaming across a diverse range of titles on our secure, user-friendly platform.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-2 mt-3">
              <div className="build-wrapper">
                <h3 className="build-heading pastel-purple">SCALABILITY</h3>
                <p className="text-white">
                  Experience premium, seamless gaming across a diverse range of titles on our secure, user-friendly platform.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-2 mt-3">
              <div className="build-wrapper">
                <h3 className="build-heading pastel-purple">CROSS-CHAIN BRIDGE</h3>
                <p className="text-white">
                  Experience premium, seamless gaming across a diverse range of titles on our secure, user-friendly platform.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-2 mt-3">
              <div className="build-wrapper">
                <h3 className="build-heading pastel-purple">GAME DASHBOARD</h3>
                <p className="text-white">
                  Experience premium, seamless gaming across a diverse range of titles on our secure, user-friendly platform.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-2 mt-3">
              <div className="build-wrapper">
                <h3 className="build-heading pastel-purple">GAMEDATA ANALYTICS</h3>
                <p className="text-white">
                  Experience premium, seamless gaming across a diverse range of titles on our secure, user-friendly platform.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-2 mt-3">
              <div className="build-wrapper">
                <h3 className="build-heading pastel-purple">GAME REPORTS</h3>
                <p className="text-white">
                  Experience premium, seamless gaming across a diverse range of titles on our secure, user-friendly platform.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-2 mt-3">
              <div className="build-wrapper">
                <h3 className="build-heading pastel-purple">DIGITAL MARKETPLACE</h3>
                <p className="text-white">
                  Experience premium, seamless gaming across a diverse range of titles on our secure, user-friendly platform.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-2 mt-3">
              <div className="build-wrapper">
                <h3 className="build-heading pastel-purple">WHITE LABEL</h3>
                <p className="text-white">
                  Experience premium, seamless gaming across a diverse range of titles on our secure, user-friendly platform.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 px-2 mt-3">
              <div className="build-wrapper">
                <h3 className="build-heading pastel-purple">DOCUMENTATION</h3>
                <p className="text-white">
                  Experience premium, seamless gaming across a diverse range of titles on our secure, user-friendly platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
  
export default BuildWithUs;