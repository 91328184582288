import React, { Component} from "react";

class JoinCommunity extends Component {
  render() {
    return (
      <section className="community-section">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="300">
              <h2 className="section-heading text-gray">THE BEST COMMUNITY<br></br><span className="blue">FOR GAMERS</span></h2>
            </div>
            <div className="col-12 mt-3">
              <div className="d-flex justify-content-center">
                <span className="social-icon mx-3" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="300" data-aos-delay="400">
                  <a className="social-link" href="https://twitter.com/mekagames_io" target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-x-twitter"></i>
                  </a>
                </span>
                <span className="social-icon mx-3" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="300" data-aos-delay="500">
                  <a className="social-link" href="https://t.me/mekagames_io" target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-telegram"></i>
                  </a>
                </span>
                <span className="social-icon mx-3" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="300" data-aos-delay="600">
                  <a className="social-link" href="https://discord.gg/JMgK4kUVgK" target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-discord"></i>
                  </a>
                </span>
                <span className="social-icon mx-3" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="300" data-aos-delay="700">
                  <a className="social-link" href="https://www.youtube.com/@MekaGamesStudio" target="_blank" rel="noreferrer">
                    <i className="fa-brands fa-youtube"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
  
export default JoinCommunity;