import React, { Component} from "react";
import { Link } from "react-router-dom";

import MainLogo from '../assets/img/meka-logo.png';

class Header extends Component {
  render() {
    return (
      <section className="header">
        <div className="container-fluid fluid-padding">
          <div className="row">
            <div className="col-6 px-2">
              <Link to="/">
                <img className="header-logo" src={MainLogo} alt="Meka Games Logo"></img>
              </Link>
            </div>
            <div className="col-6 px-3 text-right">
              <button className="btn header-button" onClick={(e) => {
                window.location.replace('https://demo.meka.games');
              }}>
                <p className="mb-0"><strong>Play Demo</strong></p>
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
  
export default Header;