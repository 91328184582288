import React, { Component } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, EffectCoverflow } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import Cube from "../assets/img/meka-logo-dummy.jpg";
SwiperCore.use([Autoplay,EffectCoverflow]);

class FeaturedSlider extends Component {
  render() {
    return (
      <section className="featured-slider py-5">
        <div className="container-fluid">
          <div className="row" data-aos="zoom-in-down" data-aos-easing="ease-out-cubic" data-aos-duration="400">
            <div className="col-12 px-0 text-center">
              <h2 className="section-heading dark-blue">POPULAR GAMES</h2>
              <p className=""></p>
            </div>
            <div className="col-12 mt-2 px-0">
              <Swiper
                loop={true}
                freeMode={true}
                speed={1000}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false
                }}
                centeredSlides={true}
                grabCursor={true}
                spaceBetween={25}
                slidesPerView={1.5}
                effect={'coverflow'}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 80,
                  modifier: 1.5,
                  slideShadows: false,
              }}
              breakpoints={{
                  576: {
                    slidesPerView: 2,
                    coverflowEffect: {
                      depth: 100,
                    },
                    spaceBetween: 40,
                  },
                  768: {
                    slidesPerView: 3,
                    coverflowEffect: {
                      depth: 80,
                    },
                    spaceBetween: 30,
                  },
                  1024: {
                    slidesPerView: 4,
                    coverflowEffect: {
                      depth: 100,
                    },
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 4,
                    coverflowEffect: {
                        depth: 120,
                    },
                    spaceBetween: 50,
                  },
                  1600: {
                    slidesPerView: 6,
                    coverflowEffect: {
                        depth: 120,
                    },
                    spaceBetween: 50,
                  },
              }}
              >
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
                <SwiperSlide><img class="slider-img" src={Cube} alt="game-cover"></img></SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
  
export default FeaturedSlider;