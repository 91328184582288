import React, { Component } from "react";

class HeroBanner extends Component {
  render() {
    return (
      <section className="hero-banner">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="hero-wrapper">
                <video className="hero-video" src="https://d1a5dji99swj96.cloudfront.net/hero-bg-video.mp4" autoPlay loop playsInline muted></video>
                <div className="hero-text-wrapper">
                  <h1 className="text-white hero-heading-1">WELCOME TO</h1>
                  <h1 className="light-blue hero-heading-2">WEB3 GAMING</h1>
                  <h3 className="text-white mt-5">EXPLORE NOW</h3>
                  <span className="text-white mouse-scroll"><i className="fa-duotone fa-angles-down"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
  
export default HeroBanner;