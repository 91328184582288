import React, { Component} from "react";

class MekaData extends Component {
  render() {
    return (        
      <section className="data-section">
        <div className="container">
          <div className="row px-2 py-5">
            <div className="col-12 mt-5 text-center" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="200">
              <h2 className="section-heading text-gray">WHY CHOOSE MEKA?</h2>
            </div>
            <div className="col-12 mb-5">
              <div className="row">
                <div className="col-lg-4 text-center mt-5 mt-lg-3" data-aos="zoom-out" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="300">
                  <span className="pastel-purple data-icon"><i className="fa-duotone fa-game-console-handheld"></i></span>
                  <p className="data-numbers pastel-purple mb-0">2,500+</p>
                  <h5 className="text-gray mb-0 ml-2">Games Listing</h5>
                </div>
                <div className="col-lg-4 text-center mt-5 mt-lg-3" data-aos="zoom-out" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="400">
                  <span className="pastel-blue data-icon"><i className="fa-duotone fa-users"></i></span>
                  <p className="data-numbers pastel-blue mb-0">12,000+</p>
                  <h5 className="text-gray mb-0 ml-2">Daily Active Users</h5>
                </div>
                <div className="col-lg-4 text-center mt-5 mt-lg-3" data-aos="zoom-out" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="500">
                  <span className="pastel-green data-icon"><i className="fa-duotone fa-money-bill-trend-up"></i></span>
                  <p className="data-numbers pastel-green mb-0">$200K USD</p>
                  <h5 className="text-gray mb-0 ml-2">Daily Transactions</h5>
                </div>
              </div>
            </div>
            <div className="col-12 my-5 text-center" data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="600" data-aos-delay="600">
              <h2 className="text-white">Ready, <span className="light-blue">GAME</span>ON!</h2>
              <p className="text-white mb-0 ml-2">Start your Web3 gaming journey with us today.</p>
              <button 
                className="btn cta-button mx-2 mt-3"
                data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="400" data-aos-delay="700"
                onClick={(e) => {
                  window.location.replace('https://demo.meka.games');
                }}
              >
                <p className="mb-0"><strong>Play Now</strong></p>
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
  
export default MekaData;