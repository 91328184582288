import React, { Component } from "react";

// Logo Images
import CDAOLogo from "../assets/img/brand-logo/cdao.png";
import UbisoftLogo from "../assets/img/brand-logo/ubisoft.png";
import PolygonLogo from "../assets/img/brand-logo/polygon.png";
import UnityLogo from "../assets/img/brand-logo/unity.png";
import QuickNodeLogo from "../assets/img/brand-logo/quicknode.png";
import BinanceLogo from "../assets/img/brand-logo/binance.png";
import PinataLogo from "../assets/img/brand-logo/pinata.png";
import AmazonLogo from "../assets/img/brand-logo/amazon.png";
import OpenSeaLogo from "../assets/img/brand-logo/opensea.png";
import BaseLogo from "../assets/img/brand-logo/base.png";
import StripeLogo from "../assets/img/brand-logo/stripe.png";
import PaypalLogo from "../assets/img/brand-logo/paypal.png";

class BrandLogo extends Component {
  render() {
    return (
      <section className="brands-section">
        <div className="container">
          <div className="row py-5" data-aos="zoom-in" data-aos-easing="ease-out-cubic" data-aos-duration="400">
            <div className="col-12 text-center">
              <h3 className="text-gray">EMPOWERED BY</h3>
            </div>
            <div className="col-12">
              <div className="row px-2 px-sm-0">
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={PinataLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={UnityLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={UbisoftLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={StripeLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={PaypalLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={AmazonLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={CDAOLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={OpenSeaLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={BaseLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={PolygonLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={BinanceLogo} alt="Brand Logo"></img>
                </div>
                <div className="col-4 col-sm-3 col-lg-2 text-center">
                  <img className="brand-logo" src={QuickNodeLogo} alt="Brand Logo"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
  
export default BrandLogo;